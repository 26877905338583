<template>
  <div class="CEESAR-Link" v-on:click="OnClick">
        <div class="CEESAR-LinkLabel">{{label}}</div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-MenuLink",
    props:{
        label:{type: String, required: true}
    },
    methods:
    {
        OnClick()
        {
            this.$emit("click");
        }
    }
}
</script>

<style lang="less">

</style>